
import { ref, Ref, watchEffect, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { EMPTY_COMMITMENT, COMMITMENT } from "../../types";
import { Actions } from "@/store/enums/StoreEnums";
import { ErrorMessage, Field, Form } from "vee-validate";
import dayjs from "dayjs";
import { Modal } from "bootstrap";
import "dayjs/locale/es";

export default {
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const commitments: Ref<Array<COMMITMENT>> = ref(
      store.getters.getCommitments
    );
    const selectedCommitment: Ref<COMMITMENT> = ref({ ...EMPTY_COMMITMENT });
    const showSpinner = ref(false);
    const showSkeleton = ref(Array(commitments.value.length).fill(false));
    const editing = ref(false);
    const adding = ref(false);
    const { t, te, locale } = useI18n();
    const translate = (text: string) => (te(text) ? t(text) : text);

    const addNewCommitment = () => {
      store.dispatch(Actions.FETCH_CHANGE_HEIGHT, true);
      selectedCommitment.value = { ...EMPTY_COMMITMENT };
      adding.value = true;
      editing.value = true;

      nextTick(() => {
        const inputElement = document.getElementById("focusTitle");
        if (inputElement instanceof HTMLInputElement) {
          inputElement.focus();
        }
      });
    };

    const isValidDate = (date1: string, date2: string): boolean => {
      return dayjs(date1).isValid() || dayjs(date2).isValid();
    };

    const isDateOverdue = (date) => {
      const currentDate = new Date();
      const commitmentDate = new Date(date);
      return commitmentDate < currentDate;
    };

    const formatDate = (date: string) => {
      const parsedDate = dayjs(date);
      const lang = locale.value;

      const formatString = lang === "en" ? "M/D/YY" : "D/M/YY";
      return parsedDate.locale(lang).format(formatString);
    };

    const datePickerEndAtOptions = (date) => {
      return date < selectedCommitment.value.start_at;
    };

    const datePickerStartAtOptions = (date) => {
      if (selectedCommitment.value.end_at) {
        return date > selectedCommitment.value.end_at;
      }
      return false
    };

    const editCommitment = (commitment: COMMITMENT) => {
      store.dispatch(Actions.FETCH_CHANGE_HEIGHT, true);
      selectedCommitment.value = { ...commitment };
      editing.value = true;
      adding.value = false;
    };

    const cancelDeleteCommitment = () => {
      const modalElement = document.getElementById("_app_remove_confirm_modal");
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    const cancelEdit = () => {
      editing.value = false;
      store.dispatch(Actions.FETCH_CHANGE_HEIGHT, false);
      store.dispatch(Actions.LOAD_OBJECTIVE, {
        objective: null,
        payload: true,
      });
    };

    const confirmRemoveCommitment = () => {
      store
        .dispatch(Actions.DELETE_COMMITMENT, selectedCommitment.value.id)
        .then(() => {
          cancelDeleteCommitment();
          cancelEdit();
        });
    };

    const handleCheckboxChange = (
      id: number,
      status: number,
      index: number
    ) => {
      showSkeleton.value[index] = true;
      store
        .dispatch(Actions.EDIT_COMMITMENT_CHECK, { id, status: !status })
        .then(() => {
          store.dispatch(Actions.LOAD_OBJECTIVE, {
            objective: null,
            payload: true,
          });
          store
            .dispatch(Actions.FETCH_COMMITMENTS)
            .then(() => (showSkeleton.value[index] = false));
        });
    };

    const saveCommitment = async () => {
      if (selectedCommitment.value.id) {
        await store.dispatch(Actions.EDIT_COMMITMENT, selectedCommitment.value);
      } else {
        await store.dispatch(Actions.ADD_COMMITMENT, selectedCommitment.value);
      }
      cancelEdit();
    };

    watchEffect(() => {
      commitments.value = store.getters.getCommitments;
      showSpinner.value = store.getters.getCommitmentsFetching;
      if (store.getters.getSelectedObjectiveCamelCase) {
        selectedCommitment.value = { ...EMPTY_COMMITMENT };
        editing.value = false;
        adding.value = false;
        store.dispatch(Actions.FETCH_CHANGE_HEIGHT, false);
      }
    });


    const isInvalidDate = (date) => {
      return formatDate(date) === 'Invalid Date';
    }

    const formatDateIfExists = (date) => {
      return isInvalidDate(date) ? '' : formatDate(date);
    }

    const isDateRange = (start, end) => {
      return !isInvalidDate(start) && !isInvalidDate(end);
    }

    const isValidDateRange = (start, end) => {
      return !isInvalidDate(start) || !isInvalidDate(end);
    }

    return {
      isInvalidDate,
      formatDateIfExists,
      isDateRange,
      isValidDateRange,
      translate,
      commitments,
      editing,
      adding,
      showSpinner,
      showSkeleton,
      addNewCommitment,
      editCommitment,
      saveCommitment,
      cancelEdit,
      selectedCommitment,
      datePickerEndAtOptions,
      datePickerStartAtOptions,
      handleCheckboxChange,
      cancelDeleteCommitment,
      confirmRemoveCommitment,
      formatDate,
      isValidDate,
      isDateOverdue,
    };
  },
};
