
import { defineComponent, computed, ref, Ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import RecursiveDropdown from "@/components/app/Objectives/RecursiveDropdown.vue";
import { Objective, OBJECTIVE_TYPE_LIST, UNITS, FREQUENCIES } from "../types";

export default defineComponent({
  name: "Left",

  components: {
    ErrorMessage,
    Field,
    Form,
    RecursiveDropdown
  },

  setup() {
    const decimalRegex = /^-?(\d)*(\.)?([0-9]{1,2})?$/;
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text: string) => (te(text) ? t(text) : text);
    const form: Ref<Objective> = ref(
      store.getters.getSelectedObjectiveCamelCase
    );
    const loadingObjective = ref(store.getters.getLoadingObjective);
    const selectedObjective = ref(store.getters.getSelectedObjectiveCamelCase);
    const formChanged = ref(store.getters.getFromChange);
    const formRef = ref<InstanceType<typeof Form> | null>(null);
    const objectiveTypeList = ref(OBJECTIVE_TYPE_LIST);
    const units = ref(UNITS);
    const frequencies = ref(FREQUENCIES);

    const periods = computed(() => store.getters.getAllCycles);
    const selectedPeriods = computed(() => store.getters.getCycle);
    const members = computed(() => store.getters.getMembers);
    const objectives = computed(() => store.getters.getObjectives);

    const isDecimal = (value: string | undefined | null): boolean =>
      typeof value === "string" && decimalRegex.test(value);

    const suggestion = (name: string) => (form.value.unit = name);

    const setType = (typeToSet: string) => (form.value.type = typeToSet);

    watch(
      () => store.getters.getSelectedObjectiveCamelCase,
      function(currentValue) {
        form.value = currentValue;
      }
    );

    onMounted(() => {
      if (form.value.period === null) {
        form.value.period = selectedPeriods.value;
      }
    });

    watch(
      () => form.value.add,
      newVal => {
        if (newVal) {
          form.value.user.id = 0;
        }
      }
    );

    watch(
      () => form.value.period,
      newVal => {
        if (newVal == null) {
          form.value.period = selectedPeriods.value.id;
          form.value.parent = selectedObjective.value.id;
        }
      }
    );

    watch(
      () => store.getters.getLoadingObjective,
      newValue => {
        loadingObjective.value = newValue;
      }
    );

    watch(
      () => store.getters.getFromChange,
      newValue => {
        formChanged.value = newValue;
      }
    );

    const formValidate = Yup.object().shape({
      title: Yup.string()
        .label("Title")
        .required(`${translate("objectiveTitle")} ${translate("isRequired")}`)
        .max(254, translate("maxTitle")),
      objectiveType: Yup.string()
        .label("objectiveType")
        .required(`${translate("objectiveType")} ${translate("isRequired")}`),
      startValue: Yup.string()
        .nullable()
        .label("Start Value")
        .test("is-decimal", "invalid decimal", isDecimal)
        .when("objectiveType", (objectiveType, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
      currentValue: Yup.string()
        .nullable()
        .label("Current Value")
        .test("is-decimal", "invalid decimal", isDecimal),
      targetValue: Yup.string()
        .nullable()
        .label("Target Value")
        .test("is-decimal", "invalid decimal", isDecimal)
        .when("objectiveType", (objectiveType: string, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
      user: Yup.string()
        .required()
        .label("User")
        .notOneOf(["0"], translate("isRequired"))
        .nullable(),
      period: Yup.string()
        .required()
        .label("Period")
        .nullable(),
      frequency: Yup.string()
        .label("Frequency Value")
        .when("objectiveType", (objectiveType: string, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
      unit: Yup.string()
        .label("Unit")
        .nullable()
        .when("objectiveType", (objectiveType: string, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        })
    });

    watch(
      () => [form.value.title, form.value.type],
      function([newTitle, newType]) {
        store.dispatch(Actions.SYNC_OBJECTIVE_TITLE, {
          title: newTitle,
          type: newType
        });
      }
    );

    const submitForm = async () => {
      if (formRef.value) {
        if (form.value.add === false || form.value.add == undefined) {
          store.dispatch(Actions.UPDATE_OBJECTIVE, form.value);
        } else {
          if (form.value.id) {
            form.value.parent = form.value.id;
            store.dispatch(Actions.SAVE_OBJECTIVE, form.value);
          }
        }
        store.dispatch(Actions.RESET_EQUAL, false);
      }
    };

    const handleInput = () => {
      store.dispatch(Actions.IS_EQUAL, form.value);
    };

    const isCicloRunningOrFinished = selectedPeriods => {
      return (
        selectedPeriods.status === "running" ||
        selectedPeriods.status === "finished"
      );
    };

    return {
      translate,
      periods,
      units,
      frequencies,
      selectedPeriods,
      members,
      objectiveTypeList,
      formValidate,
      formRef,
      submitForm,
      form,
      suggestion,
      setType,
      objectives,
      isCicloRunningOrFinished,
      loadingObjective,
      formChanged,
      handleInput
    };
  }
});
