const DocMenuConfig: object = [
  {
    pages: [
      {
        heading: "myOrganizations",
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator"
      }
      // {
      //   heading: "myReports",
      //   route: "/organization/:id/reports",
      //   svgIcon: "media/icons/duotone/Design/Magic.svg",
      //   fontIcon: "bi-app-indicator"
      // }
    ]
  },
  {
    heading: "myAccount",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "settings",
        route: "/account",
        svgIcon: "media/icons/duotone/General/User.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "userData",
            route: "/crafted/account/user-account"
          },
          {
            heading: "passwordReset",
            route: "/crafted/account/user-password"
          }
        ]
      }
    ]
  }
];

export default DocMenuConfig;
