
import { defineComponent } from "vue";
import { useStore } from "vuex";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTUserMenu
  },
  data() {
    return {
      hasUnreadNotifications() {
        alert("@todo: Load notifications");
      }
    };
  },
  setup() {
    const store = useStore();
    const { isUserAuthenticated } = store.getters;
    const { t, te } = useI18n();
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      isAuthenticated: isUserAuthenticated,
      translate
    };
  }
});
