<template>
  <template
    v-if="element.type != 'key_result' && element.id != selectedObjective"
  >
    <option :value="element.id">
      {{ "&nbsp;".repeat(level) }} {{ element.title }}
    </option>
    <template
      v-for="subObjective in element.subObjectives"
      v-bind:key="subObjective.id"
    >
      <RecursiveDropdown :level="level + 1" :element="subObjective" />
    </template>
  </template>
</template>

<script>
export default {
  name: "RecursiveDropdown",
  props: {
    level: {
      typer: Number,
      default: 0
    },
    element: {
      type: Object
    },
    selectedObjective: {
      type: Number
    }
  }
};
</script>
