
import { defineComponent, computed } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { version } from "@/core/helpers/documentation";
import { isDocPage } from "@/core/helpers/documentation";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import useEmitter from "@/core/plugins/emitter.js";
import ELearningHelperModal from "@/components/app/ui/ELearningHelperModal.vue";
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String
  },
  components: {
    KTTopbar,
    KTMenu,
    ELearningHelperModal
  },
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const emitter = useEmitter();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const CurrenPeriod = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.id ? currentCycle : null;
    });

    const isPlanning = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.status
        ? currentCycle.status === "planning"
        : false;
    });

    const openObjectiveModalOmi = () => {
      emitter.emit("openSubObjectiveModal", {
        objective: null,
        parent: null
      });
    };

    const authenticatedUser = computed(() => {
      return store.getters.currentUser;
    });

    return {
      isDocPage,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      version,
      openObjectiveModalOmi,
      translate,
      CurrenPeriod,
      authenticatedUser,
      isPlanning
    };
  }
});
