<template>
  <div class="modal fade" id="support_modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header">
          <h2>
            {{ translate("supportTitle") }}
          </h2>
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal"
            id="closeSupportModalButton">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>

        <!--begin::Modal body-->
        <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
          <!--begin::Organization info-->
          <div class="d-flex flex-column pt-6">
            <!--begin::Row-->
            <div class="row">
              <!--begin::Organization form-->
              <div class="col-12">
                <Form id="support_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
                  @submit="send" :validation-schema="submitSupportTicket">

                  <!--begin:: Name-->
                  <div class="row mb-10">
                    <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span class="required">{{ translate("name") }}</span>
                    </label>
                    <Field autofocus type="text" class="form-control form-control-solid" placeholder="" name="name"
                      v-model="currentUser.name" id="name" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>

                  <!--begin:: Surname-->
                  <div class="row mb-10">
                    <div class="col-12">
                      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span class="required">{{ translate("lastName") }}</span>
                      </label>
                      <Field type="text" class="form-control form-control-solid" v-model="currentUser.lastname"
                        placeholder="" name="surname" id="surname" />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="surname" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>

                  <!--begin:: Email-->
                  <div class="fv-row mb-7">
                    <label class="required form-label fw-bolder text-dark fs-6">
                      {{ translate("email") }}
                    </label>
                    <Field class="form-control form-control-lg form-control-solid" v-model="currentUser.email"
                      type="email" placeholder="" name="email" autocomplete="off" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  </div>

                  <!--begin:: phone-->
                  <div class="mb-10">
                    <!--begin::Col-->
                    <div class="col-md-12 fv-row">
                      <label class="form-label fw-bolder text-dark fs-6">
                        {{ translate("phone") }}
                      </label>
                      <Field class="form-control form-control-lg form-control-solid" v-model="currentUser.cellphone"
                        type="phone" placeholder="" name="phone" autocomplete="off" />
                      <span v-if="countryCode" :class="`flag-icon flag-icon-AZ`"></span>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="phone" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>

                  <!--begin:: text Area-->
                  <div class="row mb-10">
                    <div class="col-md-12 fv-row">
                      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span class="required">{{ translate("description") }}</span>
                      </label>

                      <el-form-item prop="details">
                        <Field name="details" v-slot="{ field }">
                          <el-input type="textarea" v-bind="field" v-model="currentUser.details" rows="3" name="details"
                            :placeholder="translate('ticketDescriptions')" />
                        </Field>
                      </el-form-item>
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="details" />
                      </div>
                    </div>
                  </div>

                  <!--begin::buttons-->
                  <div class="text-center mt-15">
                    <button ref="cancelButtonRef" id="cancel_button" type="reset" class="btn btn-light me-3"
                      data-bs-dismiss="modal">
                      <span class="indicator-label">
                        {{ translate("cancel") }}
                      </span>
                    </button>

                    <button type="submit" class="btn btn-primary save-org" :disabled="isSending">
                      <span class="indicator-label">
                        {{ translate("send") }}
                      </span>
                      <span class="custom-spinner" v-show="isSending"></span>
                    </button>
                  </div>
                </Form>
                <!--end::Form-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Plans-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Upgrade plan-->
</template>

<script>
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import "yup-phone-lite";

export default defineComponent({
  name: "support_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },


  setup() {

    const store = useStore();
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };
    const currentUser = store.getters.currentUser;
    const isSending = ref(false);

    const submitSupportTicket = Yup.object().shape({
      name: Yup.string().required(
        translate("name") + " " + translate("isRequired")
      ),
      surname: Yup.string().required(
        translate("surname") + " " + translate("isRequired")
      ),
      email: Yup.string()
        .min(4)
        .required(translate("email") + " " + translate("isRequired"))
        .email(translate("email") + " " + translate("validEmail")),
      phone: Yup.string().phone(null, translate("phoneNumber")),
      details: Yup.string().required(
        translate("description") + " " + translate("isRequired")
      )
    });

    const send = async () => {
      isSending.value = true;
      try {
        await store.dispatch(Actions.SEND_SUPPORT, currentUser)
        document.getElementById('closeSupportModalButton').click();
      } catch (error) {
        console.error("Error sending support:", error);
      } finally {
        isSending.value = false;

      }

    };

    return {
      translate,
      submitSupportTicket,
      send,
      currentUser,
      isSending
    };
  },
});
</script>

<style>
#kt_modal_new_card_submit:disabled,
#kt_modal_new_card_submit[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}

.save-org {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
