
import { ref, Ref, watchEffect, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import { NOTE } from "../../types";
import { Field } from "vee-validate";
import "dayjs/locale/es";

export default {
  components: {
    Field,
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  setup() {
    const store = useStore();

    const newComment = ref("");
    const comments: Ref<Array<NOTE>> = ref(store.getters.getNotes);
    const showSpinner = ref(true);
    const editing = ref(false);
    const editingComment = ref<NOTE | null>(null);
    const { t, te, locale } = useI18n();
    const translate = (text: string) => (te(text) ? t(text) : text);

    const cancelComment = () => {
      newComment.value = "";
      editing.value = false;
      editingComment.value = null;
    };

    const cancelDeleteNote = () => {
      const modalElement = document.getElementById("_app_remove_confirm_note");
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    const formatDate = (date: string) => {
      const parsedDate = dayjs(date);
      const lang = locale.value;

      const formatString =
        lang === "en" ? "dddd, D MMMM YYYY h:mm A" : "dddd D MMMM YYYY H:mm";
      return parsedDate.locale(lang).format(formatString);
    };

    const getMembers = (userId: number) => {
      const user = store.getters.getMembers.find(
        (member) => member.user === userId
      );
      if (user) {
        return `${user.name} ${user.lastname}`;
      } else {
        return "";
      }
    };

    const saveComment = () => {
      showSpinner.value = true;
      if (editingComment.value) {
        store.dispatch(Actions.EDIT_NOTE, editingComment.value);
      } else {
        store.dispatch(Actions.ADD_NOTE, newComment.value);
      }
      cancelComment();
    };

    const formattedText = (text) => {
      return text.replace(/\n/g, "<br />");
    };

    const editComment = (comment) => {
      editing.value = true;
      editingComment.value = { ...comment };
      newComment.value = "";
    };

    const deleteComment = () => {
      showSpinner.value = true;
      store.dispatch(Actions.DELETE_NOTE, editingComment.value);
      cancelComment();
      cancelDeleteNote();
    };

    watchEffect(() => {
      comments.value = store.getters.getNotes;
      showSpinner.value = store.getters.getNotesFetching;
    });

    const setEditing = (val: boolean) => {
      newComment.value = "";
      editing.value = val;
    };

    watch(
      () => store.getters.getSelectedObjectiveCamelCase,
      function () {
        setEditing(false);
      }
    );

    return {
      translate,
      newComment,
      comments,
      editing,
      cancelComment,
      formattedText,
      editComment,
      deleteComment,
      editingComment,
      saveComment,
      showSpinner,
      formatDate,
      getMembers,
      cancelDeleteNote,
      setEditing,
    };
  },
};
