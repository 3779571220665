
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "_app_objective_procede",
  props: {
    action: {
      type: String
    }
  },

  setup(props) {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const confirmRemoving = () => {
      const closeButton: HTMLElement | null = document.getElementById(
        "removing_objective_procede"
      );

      if (closeButton) closeButton.click();

      if (props.action === "NEXT") {
        store.dispatch(Actions.NEXT_OBJECTIVE);
      } else {
        store.dispatch(Actions.PREVIOUS_OBJECTIVE);
      }
      store.dispatch(Actions.RESET_EQUAL, false);
    };

    return {
      translate,
      confirmRemoving
    };
  }
});
