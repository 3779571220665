
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ELearningHelperModal from "@/components/app/ui/ELearningHelperModal.vue";
import useEmitter from "@/core/plugins/emitter.js";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    Dropdown1,
    ELearningHelperModal
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const isUserAuthenticated = store.getters.isUserAuthenticated;
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      emitter,
      isAuthenticated: isUserAuthenticated,
      translate
    };
  },
  methods: {
    openObjectiveModal() {
      this.emitter.emit("open-objective-modal");
    },
    openObjectiveModalOmi() {
      this.emitter.emit("open-objective-modal-omi");
    },
    createOrganization() {
      this.emitter.emit("open-organization-new-modal", null);
    },
    goPlanificationGuide() {
      this.$router.push("/planification-guide");
    }
  }
});
