const AuthMenuConfig: object = [
  {
    pages: [
      {
        heading: "Inicio",
        route: "/presentation",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator"
      }
    ]
  },
  {
    heading: "Mi cuenta",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Comencemos",
        svgIcon: "media/icons/duotone/Interface/Lock.svg",
        fontIcon: "bi-sticky",
        sub: [
          {
            heading: "Ingresa",
            route: "/sign-in"
          },
          {
            heading: "Crea tu cuenta",
            route: "/sign-up"
          }
        ]
      }
    ]
  }
];

export default AuthMenuConfig;
