
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  nextTick,
  watchEffect,
  Ref,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Left from "./Left.vue";
import Right from "./Right.vue";
import ProceedAnyway from "./components/ProceedAnyway.vue";
import PercentSection from "../PercentageSection.vue";
import { Objective, SyncTitle } from "../types";

export default defineComponent({
  name: "_app_objective_main_Drawer",

  components: {
    Left,
    Right,
    PercentSection,
    ProceedAnyway,
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    const form: Ref<Objective> = ref(
      store.getters.getSelectedObjectiveCamelCase
    );

    const syncTitle: Ref<SyncTitle> = ref(
      store.getters.getSelectedObjectiveSyncTitle
    );

    const canNavigate = ref(true);
    const shouldFloat = ref(false);
    const actionType = ref("NEXT");
    const formChanged = ref(store.getters.getFromChange);

    const modalAttributes = computed(() => {
      return {
        "data-bs-toggle": formChanged.value ? "modal" : null,
        "data-bs-target": formChanged.value ? "#_app_objective_procede" : null,
        "data-bs-placement": formChanged.value ? "top" : null,
      };
    });

    let observerDrawer: MutationObserver | null = null;
    let observer: IntersectionObserver | null = null;

    const next = () => {
      if (!formChanged.value) {
        store.dispatch(Actions.NEXT_OBJECTIVE);
      } else {
        actionType.value = "NEXT";
      }
    };

    const prev = () => {
      if (!formChanged.value) {
        store.dispatch(Actions.PREVIOUS_OBJECTIVE);
      } else {
        actionType.value = "PREV";
      }
    };

    const newSubObjective = () => {
      if (form.value.add == undefined || form.value.add === false) {
        store.dispatch(Actions.PREV_OBJECTIVE, form.value);
        store.dispatch(Actions.ADD_SUBOBJECTIVE, form.value);
      } else {
        store.dispatch(Actions.RESTORE_PREV_OBJECTIVE);
      }
    };

    const isTypeUser = computed(() => {
      const currentOrganization = store.getters.getOrganization;
      return (
        (Array.isArray(currentOrganization)
          ? currentOrganization[0]
          : currentOrganization) && currentOrganization.role === "user"
      );
    });

    const isPlanning = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.status
        ? currentCycle.status === "planning"
        : false;
    });

    const drawerClosed = () => {
      const drawer = document.querySelector("#kt_objective") as HTMLElement;

      if (!drawer.classList.contains("drawer-on")) {
        store.dispatch(Actions.RESET_SELECTED_OBJECTIVE);
        store.dispatch(Actions.RESET_EQUAL, false);
      }
    };

    const isBlue = (element) => {
      if (
        element.type === "key_result" &&
        element.frequency === "single" &&
        new Date(element.differentiatedStart).getTime() > new Date().getTime()
      ) {
        return true;
      }
      return false;
    };

    onMounted(() => {
      setTimeout(() => {
        const drawer = document.querySelector("#kt_objective") as HTMLElement;
        observerDrawer = new MutationObserver(drawerClosed);
        if (drawer) {
          observerDrawer.observe(drawer, { attributes: true });
        }
      }, 500);

      nextTick(() => {
        const closeButton = document.getElementById("main_modal_track");

        if (closeButton) {
          observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              shouldFloat.value = !entry.isIntersecting;
            });
          });

          observer.observe(closeButton);
        }
      });
    });

    onBeforeUnmount(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    watchEffect(() => {
      form.value = store.getters.getSelectedObjectiveCamelCase;
      syncTitle.value = store.getters.getSelectedObjectiveSyncTitle;
      formChanged.value = store.getters.getFromChange;
    });

    return {
      translate,
      canNavigate,
      next,
      prev,
      shouldFloat,
      form,
      isBlue,
      syncTitle,
      isTypeUser,
      isPlanning,
      newSubObjective,
      modalAttributes,
      actionType,
    };
  },
});
