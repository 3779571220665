
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";

// >>>>>>>>> +++ <<<<<<<<<
// >>> Main menu: Original
// import MainMenuConfig from "@/core/config/MainMenuConfig";
// >>>>>>>>> +++ <<<<<<<<<

/** DEVELOPMENT */
import MainMenuConfig from "@/core/config/AppMenuConfig";
import AuthenticationMenuConfig from "@/core/config/AuthenticationMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const { isUserAuthenticated } = store.getters;
    const MenuConfig = isUserAuthenticated
      ? MainMenuConfig
      : AuthenticationMenuConfig;

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      isAuthenticated: isUserAuthenticated,
      hasActiveChildren,
      headerMenuIcons,
      // MainMenuConfig,
      // AuthenticationMenuConfig,
      MenuConfig,
      translate
    };
  }
});
