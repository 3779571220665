
import { defineComponent, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Commitment from "./components/Commitments.vue";
import Notes from "./components/Notes.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Right",

  components: {
    Notes,
    Commitment
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text: string) => (te(text) ? t(text) : text);

    const sectionHeight = ref("50%");

    const showBlur = ref(true);

    watch(
      () => store.getters.getSelectedObjectiveCamelCase,
      function(currentValue) {
        showBlur.value = currentValue.add;
        store.dispatch(Actions.FETCH_NOTES, currentValue.id);
        store.dispatch(Actions.FETCH_COMMITMENTS, currentValue.id);
      }
    );

    watch(
      () => store.getters.getChangeHeight,
      function(currentValue) {
        if (currentValue) {
          sectionHeight.value = "30rem";
        } else {
          sectionHeight.value = "50%";
        }
      }
    );

    return {
      translate,
      sectionHeight,
      showBlur
    };
  }
});
