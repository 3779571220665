
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStore } from "vuex";
import { Objective } from "./types";

export default {
  name: "KeyPad",

  props: {
    element: {
      type: Object as () => Objective,
      required: true
    }
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();

    const percentage = computed(() => store.getters.getPercentage);

    const toDate = (dateString: string) => new Date(dateString);

    const cycle = computed(() => {
      const { start_at, end_at } = store.getters.getCycle;
      const startDate: Date = toDate(start_at);
      const endDate: Date = toDate(end_at);
      const percent: number =
        (new Date().getTime() - startDate.getTime()) /
        (endDate.getTime() - startDate.getTime());
      return (percent > 0 ? percent.toFixed(2) : 0) as number;
    });

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate,
      percentage,
      cycle
    };
  }
};
