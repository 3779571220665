
import { defineComponent } from "vue";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const notifications = [
      {
        title: "Invitación a Organización",
        description:
          "Fuiste invitado por '[UserName]' a participar en la Organización [OrganizationName]",
        time: "1 hr",
        icon: "media/icons/duotone/Clothes/Crown.svg",
        state: "primary"
      },
      {
        title: "Invitación a OKR",
        description:
          "El usuario [UserName] te ha invitado a participar en este OKR",
        time: "2 hrs",
        icon: "media/icons/duotone/Code/Warning-2.svg",
        state: "danger"
      },
      {
        title: "Invitación a OKR",
        description: "Tienes una invitación pendiente a participar en este OKR",
        time: "5 hrs",
        icon: "media/icons/duotone/Communication/Group.svg",
        state: "warning"
      }
      // {
      //   title: "Project Redux",
      //   description: "New frontend admin theme",
      //   time: "2 days",
      //   icon: "media/icons/duotone/General/Thunder.svg",
      //   state: "success",
      // },
      // {
      //   title: "Project Breafing",
      //   description: "Product launch status update",
      //   time: "21 Jan",
      //   icon: "media/icons/duotone/Communication/Flag.svg",
      //   state: "primary",
      // },
      // {
      //   title: "Banner Assets",
      //   description: "Collection of banner images",
      //   time: "21 Jan",
      //   icon: "media/icons/duotone/Design/Image.svg",
      //   state: "info",
      // },
      // {
      //   title: "Icon Assets",
      //   description: "Collection of SVG icons",
      //   time: "20 March",
      //   icon: "media/icons/duotone/Design/Component.svg",
      //   state: "warning",
      // },
    ];

    return {
      notifications
    };
  }
});
